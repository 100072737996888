<template>
  <v-container fluid>
    <v-card flat>
      <v-row>
        <v-col cols="12" sm="6" md="12">
          <listitemspages
            :selitem="selitem"
            :qSelect="Qselect"
            :qSelectall="Qselectall"
            :PageResponse="'demandesdetail'"
            :AllResponse="'alldemandesdetail_suivi'"
            :title="'Suivi des demandes d\'offres'"
            :headers="headers"
            :field="'CREATED_AT'"
            :filename="'Suivi Demandes'"
            :sheetname="'Suivi Demandes'"
            @rowselect="ProductChange"
            :showedit="false"
            :key="klist"
            :ipg="15"
            :Add="false"
            :del="false"
            @col_btn1_click="solder_ligne"
            :search_elm="filtre"
            :multiple="false"
            :groupby="[0, 1, 3]"
            @file_deleted="file_deleted"
            @file_uploaded="file_uploaded"
            :multiple_icon="'mdi-close-circle-multiple-outline'"
          >
          </listitemspages>
        </v-col>
      </v-row>

      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        top
        :color="snackbar_color"
      >
        {{ snackbar_text }}
      </v-snackbar>
      <confirmdialog
        ref="confirm"
        :key="kc"
        :is_motif="is_motif"
        @motif="get_motif"
      />
    </v-card>
  </v-container>
</template>

<script>
import DEMANDES_DETAILS from "../graphql/Demande/DEMANDES_DETAIL.gql";
import ALLDEMANDES_SUIVI from "../graphql/Demande/ALLDEMANDES_SUIVI.gql";
import UPDATE_DEMANDE_DETAIL from "../graphql/Demande/UPDATE_DEMANDE_DETAIL.gql";
import { spec_tech, image_prod } from "print/data.js";
export default {
  components: {
    listitemspages: () => import("../components/ListItemsPages.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    filtre: "",

    message: "",
    alert: false,
    is_motif: false,
    motif: "",
    type_alert: "info",
    selitem: {},

    klist: 100,
    kc: 200,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    headers: [
      {
        text: "N° Demande",
        value: "no_da",
        enum: "NO_DA",
        selected: true,
        warned: true,
        width: "8%",
        index: 0,
      },

      {
        text: "Date",
        value: "date_da",
        enum: "DATE_DA",
        slot: "date",
        selected: true,
        expire: true,
        width: "7%",
        index: 1,
      },
      {
        text: "Code Prod",
        align: "text-left",
        value: "code",
        enum: "CODE",
        filter: true,
        selected: false,
        index: 2,
      },
      {
        text: "Client",
        align: "text-left",
        value: "tier_name",
        enum: "TIER_NAME",
        filter: true,
        selected: true,
        index: 3,
      },
      {
        text: "Produit",
        value: "label",
        enum: "LABEL",
        warned: true,
        selected: true,
        index: 4,
      },

      {
        text: "Unité",
        value: "unit_name",
        enum: "UNIT_NAME",
        selected: true,
        width: "4%",
        index: 5,
      },
      {
        text: "Qte",
        value: "qte",
        enum: "QTE",
        selected: true,
        slot: "cur",
        align: "end",
        width: "8%",
        index: 6,
      },
      {
        text: "Aperçu",
        value: "preview_file",
        align: "left",
        slot: "upload/download",
        upload: false,
        download: true,
        preview: true,
        delete: false,
        column2: "produit_id",
        valeur2: null,
        sh_upload: false,
        sh_delete: false,
        var_name: "demandedetail",
        update_query: UPDATE_DEMANDE_DETAIL,
        sortable: false,
        selected: true,
        index: 7,
      },
      {
        text: "Fichier source",
        value: "url",
        align: "left",
        slot: "upload/download",
        upload: false,
        download: true,
        preview: true,
        delete: false,
        column2: "produit_id",
        valeur2: null,
        sh_upload: false,
        sh_delete: false,
        var_name: "demandedetail",
        update_query: UPDATE_DEMANDE_DETAIL,
        sortable: false,
        selected: true,
        index: 8,
      },
      {
        text: "Specification du produit",
        value: "spec_tech",
        align: "left",
        slot: "upload/download",
        upload: true,
        download: true,
        preview: true,
        delete: true,
        column2: "statut_id",
        valeur2: 0,
        sortable: false,
        selected: true,
        var_name: "demandedetail",
        upload_type: "f",
        update_query: UPDATE_DEMANDE_DETAIL,
        box_filter: "application/pdf",
        hiden: false,
        index: 9,
      },

      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        selected: false,
        index: 9,
      },

      {
        text: "Motif",
        value: "motif",
        enum: "MOTIF",
        selected: false,
        index: 10,
      },
      {
        text: "",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-close-circle-multiple-outline",
        tooltip: "Traiter cette ligne ",
        column: "statut_id",
        valeur: "0",
        sortable: false,
        hiden: false,
        width: "1%",
        index: 11,
      },
      {
        text: "",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-restore",
        tooltip: "Restorer cette ligne",
        column: "statut_id",
        valeur: "1",
        sortable: false,
        hiden: false,
        width: "1%",
        index: 12,
      },
    ],
    product: {},
    loading: false,
    technicien: false,
  }),

  computed: {
    spec_tech() {
      return spec_tech;
    },
    image_prod() {
      return image_prod;
    },
    Qselect() {
      return DEMANDES_DETAILS;
    },
    Qselectall() {
      return ALLDEMANDES_SUIVI;
    },
  },
  watch: {
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre.filter_by;
        this.selitem = this.$store.state.filtre.obj;
        this.klist++;
      },
    },
  },

  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.selitem = this.$route.params.obj;
      this.klist++;
    }
    this.technicien =
      this.$store.state.auth.includes("07070") ||
      this.$store.state.auth.includes("07086") ||
      this.$store.state.isadmin;
    this.headers[8].text = this.image_prod;
    this.headers[9].text = this.spec_tech;
    this.headers[12].hiden = !this.technicien;
    this.headers[13].hiden = !this.technicien;
    this.headers[9].upload = this.technicien;
    this.headers[9].delete = this.technicien;
  },

  mounted() {},

  methods: {
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          this.$store.dispatch("Changed", true);
          this.progress = false;
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    get_motif(m) {
      this.motif = m;
    },
    async file_uploaded() {
      this.klist++;
      this.$store.dispatch("Changed", true);
    },
    async file_deleted() {
      this.$store.dispatch("Changed", true);
    },
    async solder_ligne(item) {
      this.is_motif = item.statut_id == 1;
      this.motif = null;

      let t = item.statut_id == 1 ? "restorer" : "traiter";
      let ok = true;
      if (item.statut_id == 0 && !item.spec_tech) {
        //verifier maquette

        ok = false;
        this.snackbar_text = this.spec_tech + " manquante pour cette ligne";
        this.snackbar_color = "error";
        this.snackbar = true;
      }
      if (ok)
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir " + t + " cette ligne?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            demandedetail: {
              id: item.id,
              statut_id: item.statut_id == 1 ? 0 : 1,
              motif: this.motif,
              write_uid: this.$store.state.me.id,
            },
          };

          await this.maj(UPDATE_DEMANDE_DETAIL, v);
          this.is_motif = false;
        }
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    ProductChange(item) {
      this.selitem = item;
      this.product = item;
      this.product.cmd_detail_id = item.id;
    },
  },
};
</script>
